import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { routerConfig } from '@routes';
import { setupStore } from '@store';
import ThemeCustomization from '@themes';

import { GlobalErrorLogger } from '@providers/GlobalErrorLogger';

const store = setupStore();

function App() {
  return (
    <ReduxProvider store={store}>
      <GlobalErrorLogger>
        <ThemeCustomization>
          <RouterProvider router={routerConfig} />
        </ThemeCustomization>
      </GlobalErrorLogger>
    </ReduxProvider>
  );
}

export default App;
