import CircularProgress from '@mui/material/CircularProgress';

import { LoaderWrapper } from '@components/common/Loader/Loader.styles';

const Loader = () => (
  <LoaderWrapper>
    <CircularProgress color="primary" />
  </LoaderWrapper>
);

export default Loader;
