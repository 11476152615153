import { useEffect } from 'react';

import { useLazyAuthorizeQuery } from '@api';
import { ROUTES } from '@constants/routes';

import { useAppDispatch, useAppSelector } from '@hooks';
import { setPresetColor } from '@store/reducers/currentSettings';
import { type PresetColor } from '@themes/overrides/config';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { jwt: token } = useAppSelector((state) => state.currentSettings);

  const isMainPage = window.location.pathname === ROUTES.ROOT;
  const sid = new URL(location.href).searchParams.get('sid') ?? '';
  const presetColor = new URL(location.href).searchParams.get(
    'theme'
  ) as PresetColor;

  const [authorize, { data, isError: isAuthQueryError }] =
    useLazyAuthorizeQuery();

  useEffect(() => {
    if (!token && isMainPage) {
      const authorizeBySid = async () => {
        try {
          await authorize({ sessionId: sid });
        } catch (error) {
          console.error('Failed to authorize:', error);
        }
      };

      void authorizeBySid();
      dispatch(setPresetColor(presetColor));
    }
  }, [authorize, dispatch, isMainPage, presetColor, sid, token]);

  if (data) {
    return {
      token: data?.jwt,
    };
  } else {
    return { isAuthQueryError };
  }
};
