export interface JwtPayload {
  country: string;
  currency: string;
  customerType: string;
  exp: number;
  iat: number;
  locale: string;
  merchantCustomerId: string;
  scale: number;
  sub: string;
  virtualMode: boolean;
}

function base64UrlDecode(base64Url: string): string {
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  while (base64.length % 4) {
    base64 += '=';
  }
  return atob(base64);
}

export function decodeJwt(jwtToken: string): JwtPayload | null {
  const parts = jwtToken.split('.');
  if (parts.length !== 3) {
    return null;
  }

  const payload = parts[1];
  try {
    const decoded = JSON.parse(base64UrlDecode(payload)) as JwtPayload;
    return decoded;
  } catch (error) {
    return null;
  }
}
