export default function ImageList() {
  return {
    MuiImageList: {
      styleOverrides: {
        root: {
          '&.MuiImageList-root::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },
    },
  };
}
