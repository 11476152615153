import { API } from '@constants/apiRouting';
import { ENV } from '@constants/env';
import { INTENT_TYPE } from '@constants/intent';
import { type METHOD_NAME, METHODS } from '@constants/methods';
import { type PROVIDER_NAME, PROVIDERS } from '@constants/providers';
import { ROUTES } from '@constants/routes';
import { STATUS } from '@constants/status';

const applePayMinVersionSupport = 16;
const NODA_PAY_COUNTRY_IBAN_VALIDATION_METHODS = [
  METHODS.OPEN_BANKING,
  METHODS.COMMERZBANK,
  METHODS.HYPOVEREINS,
  METHODS.NORISBANK,
  METHODS.POSTBANK,
  METHODS.VOLKSBANKEN_RAIFFEISEN,
  METHODS.SPARKASSE,
  METHODS.DEUTSCHE_BANK,
];

export {
  ENV,
  API,
  STATUS,
  ROUTES,
  METHODS,
  PROVIDERS,
  INTENT_TYPE,
  type METHOD_NAME,
  type PROVIDER_NAME,
  applePayMinVersionSupport,
  NODA_PAY_COUNTRY_IBAN_VALIDATION_METHODS,
};
