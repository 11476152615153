import { combineReducers } from 'redux';

import { cashierApi } from '@api';

import { currentSettings } from '@store/reducers/currentSettings';
import { embeddedSettings } from '@store/reducers/embeddedSettings';
import { pciSettings } from '@store/reducers/pciSettings';
import { praxisHpfSettings } from '@store/reducers/praxisHpfSettings';

const rootReducer = combineReducers({
  pciSettings,
  currentSettings,
  embeddedSettings,
  praxisHpfSettings,
  [cashierApi.reducerPath]: cashierApi.reducer,
});

export default rootReducer;
