import { type JSX, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import Loader from '@components/common/Loader/Loader';
import useI18n from '@i18n/use18n';

function I18nProvider({ children }: { children: JSX.Element }) {
  const { i18n } = useI18n();

  return (
    <Suspense fallback={<Loader />}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </Suspense>
  );
}

export default I18nProvider;
