import { useMemo } from 'react';

import { INTENT_TYPE } from '@constants';
import { type IPaymentMethod } from '@pages/methods/types';
import { type Intent } from '@pages/payment/types';

interface IPaymentMethodData {
  currentPaymentMethod: IPaymentMethod | null;
  intent: Intent;
}

export const usePaymentMethodData = ({
  intent,
  currentPaymentMethod,
}: IPaymentMethodData) => {
  const { minAmount, maxAmount, isDepositFlow, manualInputProhibited } =
    useMemo(() => {
      return {
        minAmount: currentPaymentMethod?.minAmount ?? 10,
        maxAmount: currentPaymentMethod?.maxAmount ?? 1000,
        isDepositFlow: intent?.toUpperCase() === INTENT_TYPE.DEPOSIT,
        manualInputProhibited:
          currentPaymentMethod?.manualInputProhibited ?? false,
      };
    }, [
      currentPaymentMethod?.manualInputProhibited,
      currentPaymentMethod?.maxAmount,
      currentPaymentMethod?.minAmount,
      intent,
    ]);

  return {
    minAmount,
    maxAmount,
    isDepositFlow,
    manualInputProhibited,
  };
};
