import { Stack, type SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type TranslationKey } from '@i18n';

import { PROVIDERS } from '@constants';
import { useAppSelector } from '@hooks';

const bannerWrapperSx: SxProps = {
  // WARNING: We use platform colors here
  color: '#004085',
  marginTop: '0.625rem',
  borderColor: '#cce5ff',
  paddingBlock: '0.250rem',
  paddingInline: '0.625rem',
  backgroundColor: '#b8daff',
};

export const Banner = () => {
  const { t } = useTranslation();
  const { page, bannerMessage, paymentMethod } = useAppSelector(
    (state) => state.currentSettings
  );

  const showBanner = useMemo(() => {
    return (
      !!bannerMessage &&
      (page === 'main' || paymentMethod?.provider === PROVIDERS.MIFINITY)
    );
  }, [bannerMessage, page, paymentMethod?.provider]);

  if (!showBanner) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="center" sx={bannerWrapperSx}>
      <Typography
        paragraph
        sx={{
          marginBottom: 0,
        }}
        variant="body1"
      >
        {t(`${bannerMessage}` as TranslationKey)}
      </Typography>
    </Stack>
  );
};
