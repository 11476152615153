import { Box, type Theme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Banner } from '@components/common/Banner';
import Header from '@components/common/Header';

const MainLayout = () => {
  const isMatchesSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const resizeObserver = new ResizeObserver(() => {
    const height = document.getElementsByTagName('html')?.[0]?.offsetHeight;

    if (isMatchesSM && height) {
      window.parent.postMessage(
        JSON.stringify({
          payload: height,
          type: 'CASHIER_HEIGHT',
        }),
        '*'
      );
    }
  });

  resizeObserver.observe(document.body);

  return (
    <Box>
      <Banner />
      <Header />
      <Outlet />
    </Box>
  );
};

export default MainLayout;
