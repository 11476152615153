import { createTheme } from '@mui/material/styles';

import { type PresetColor } from '@themes/overrides/config';
import ThemeOption from '@themes/theme/index';

type ThemeMode = 'dark' | 'light';

const Palette = (mode: ThemeMode, presetColor: PresetColor) => {
  const paletteColor = ThemeOption(presetColor);

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      ...paletteColor,
    },
  });
};

export default Palette;
