import Hotjar from '@hotjar/browser';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { type RootState } from '@store';
import {
  omit,
  replaceURLParams,
  transformObjToCamelCase,
  transformObjToSnakeCase,
} from '@utils';

import { API, ENV, ROUTES } from '@constants';
import { type ITranslation } from '@i18n/types';
import { type IStatisticMutationData } from '@modules/types';
import { type IProceedPaymentRequest } from '@pages/integrations/paymentIq/types';
import {
  type IPaymentMethodList,
  type IPaymentMethodListResponse,
} from '@pages/methods/types';
import {
  type IKycProcedureMutationData,
  type ILogRequestMutationData,
  type IPayment,
  type IPaymentMethodDetails,
  type IPaymentMethodDetailsArgs,
  type IPaymentMethodDetailsResponse,
  type IPaymentRequestMutationData,
  type IPaymentResponse,
  type IPciPayment,
  type IProceedKycProcedureResponse,
  type ProceedKycProcedure,
  type ProceedKycProcedureEvent,
  type ProceedKycProcedureStatus,
} from '@pages/payment/types';
import {
  type ITransaction,
  type ITransactionResponse,
} from '@pages/status/types';
import {
  type IVirtualPayment,
  type IVirtualPaymentResponse,
} from '@pages/virtualPayment/types';
import {
  setBannerMessage,
  setKyc4PingProcessingData,
  setKyc4ProcessingData,
  setKyc5PingProcessingData,
  setKyc5ProcessingData,
  setPresetColor,
  setSessionData,
} from '@store/reducers/currentSettings';
import { decodeJwt } from '@utils/jwtDecode';

interface IAuthorizePayload {
  sessionId: string;
}

interface IAuthorizeResponse {
  jwt: string;
}

export const cashierApi = createApi({
  reducerPath: 'cashierApi',
  invalidationBehavior: 'immediately',
  baseQuery: fetchBaseQuery({
    baseUrl: ENV.VITE_CASHIER_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const isStatusPage =
        window.location.pathname === ROUTES.STATUS_TRANSACTION;

      if (!isStatusPage) {
        const token = (getState() as RootState).currentSettings.jwt ?? '';

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      }

      if (isStatusPage) {
        const transactionId =
          new URL(location.href).searchParams.get('uuid') ??
          (getState() as RootState).currentSettings.transactionId;
        const identifier =
          new URL(location.href).searchParams.get('identifier') ?? '';
        const jwt = (getState() as RootState).currentSettings.jwt ?? '';

        const token = transactionId ?? identifier ?? jwt;

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    getTranslation: build.query<ITranslation, unknown>({
      query: () => ({
        url: API.CONFIG.TRANSLATION,
      }),
    }),
    sendLog: build.mutation<unknown, IStatisticMutationData>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.LOGGING.LOG,
      }),
    }),
    sendMetric: build.mutation<unknown, IStatisticMutationData>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.LOGGING.METRIC,
      }),
    }),
    makeLog: build.mutation<unknown, ILogRequestMutationData>({
      query: ({ headers, ...body }) => ({
        body,
        headers,
        method: 'POST',
        url: API.LOGGING.LOG,
      }),
    }),
    deletePaymentInstrument: build.mutation<unknown, number>({
      query: (id) => ({
        method: 'DELETE',
        url: replaceURLParams(
          API.PAYMENT_INSTRUMENTS.REMOVE_PAYMENT_INSTRUMENT,
          { id }
        ),
      }),
    }),
    makePayment: build.mutation<IPayment, IPaymentRequestMutationData>({
      transformResponse: (response: IPaymentResponse) =>
        transformObjToCamelCase(response) as IPayment,
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.ROOT,
      }),
    }),
    makeVirtualPayment: build.mutation<
      IVirtualPayment,
      IPaymentRequestMutationData
    >({
      transformResponse: (response: IVirtualPaymentResponse) =>
        transformObjToCamelCase(response) as IVirtualPayment,
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.VIRTUAL_PAYMENT,
      }),
    }),
    proceedPayment: build.mutation<IPayment, IProceedPaymentRequest>({
      transformResponse: (response: IPaymentResponse) =>
        transformObjToCamelCase(response) as IPayment,
      query: ({ transactionUuid, ...rest }) => ({
        body: rest,
        method: 'POST',
        url: replaceURLParams(API.PAYMENT.PROCEED, {
          uuid: transactionUuid,
        }),
      }),
    }),
    getTransaction: build.query<ITransaction, string>({
      query: (uuid) => ({
        url: replaceURLParams(API.PAYMENT.TRANSACTION_DETAILS, { uuid }),
      }),
      transformResponse: (response: ITransactionResponse) =>
        transformObjToCamelCase(response) as ITransaction,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(setPresetColor(data.theme ?? 'Default'));
      },
    }),
    makePciPayment: build.mutation<IPciPayment, IPaymentRequestMutationData>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.ROOT,
      }),
      transformResponse: (response: IPaymentResponse) => {
        const transformedResponse = {
          ...(transformObjToCamelCase(
            omit(response, ['embedded_page_properties'])
          ) as object),
          embeddedPageProperties: response.embedded_page_properties,
        };
        return transformedResponse as IPciPayment;
      },
    }),
    getPaymentMethods: build.query<IPaymentMethodList, string>({
      query: (intent) => ({
        url: API.PAYMENT_METHODS,
        params: {
          intent,
        },
      }),
      transformResponse: (response: IPaymentMethodListResponse) =>
        transformObjToCamelCase(response) as IPaymentMethodList,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setBannerMessage(data?.bannerMessage));
          }
        } catch (error) {}
      },
    }),
    makeVirtualPciPayment: build.mutation<
      IPciPayment,
      IPaymentRequestMutationData
    >({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.VIRTUAL_PAYMENT,
      }),
      transformResponse: (response: IPaymentResponse) => {
        const transformedResponse = {
          ...(transformObjToCamelCase(
            omit(response, ['embedded_page_properties'])
          ) as object),
          embeddedPageProperties: response.embedded_page_properties,
        };
        return transformedResponse as IPciPayment;
      },
    }),
    getPaymentMethodDetails: build.query<
      IPaymentMethodDetails,
      IPaymentMethodDetailsArgs
    >({
      transformResponse: (response: IPaymentMethodDetailsResponse) => {
        return transformObjToCamelCase(response) as IPaymentMethodDetails;
      },
      query: (payload) => {
        const data = payload?.fields
          ? {
              ...(transformObjToSnakeCase(omit(payload, ['fields'])) as object),
              fields: payload?.fields,
            }
          : transformObjToSnakeCase(payload);

        return {
          body: data,
          method: 'POST',
          url: API.PAYMENT_METHOD_DETAILS,
        };
      },
    }),
    // TODO: refactor types
    pingProceedKyc4Procedure: build.query<
      { status: ProceedKycProcedureStatus },
      {
        event?: ProceedKycProcedureEvent;
      } & {
        processingData: Record<string, string>;
      } & IPaymentRequestMutationData &
        Partial<Omit<IKycProcedureMutationData, 'processing_data'>>
    >({
      query: ({ event, ...body }) => ({
        body,
        method: 'POST',
        url: API.INTEGRATION.KYC.PROCESSING,
        ...(event && {
          params: {
            event,
          },
        }),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setKyc4PingProcessingData(data.status));
          }
        } catch (error) {}
      },
    }),
    // TODO: refactor types
    pingProceedKyc5Procedure: build.query<
      { status: ProceedKycProcedureStatus },
      {
        event?: ProceedKycProcedureEvent;
      } & {
        processingData: Record<string, string>;
      } & IPaymentRequestMutationData &
        Partial<Omit<IKycProcedureMutationData, 'processing_data'>>
    >({
      query: ({ event, ...body }) => ({
        body,
        method: 'POST',
        url: API.INTEGRATION.KYC.PROCESSING,
        ...(event && {
          params: {
            event,
          },
        }),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setKyc5PingProcessingData(data.status));
          }
        } catch (error) {}
      },
    }),
    proceedKycProcedure: build.mutation<
      ProceedKycProcedure,
      {
        event?: ProceedKycProcedureEvent;
      } & IKycProcedureMutationData &
        IPaymentRequestMutationData
    >({
      transformResponse: (response: IProceedKycProcedureResponse) =>
        transformObjToCamelCase(response) as ProceedKycProcedure,
      query: ({ event, ...body }) => ({
        body,
        method: 'POST',
        url: API.INTEGRATION.KYC.PROCESSING,
        ...(event && {
          params: {
            event,
          },
        }),
      }),
      async onQueryStarted({ event }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            if (event === 'KYC_4') {
              dispatch(setKyc4ProcessingData(data));
            }
            if (event === 'KYC_5') {
              dispatch(setKyc5ProcessingData(data));
            }
          }
        } catch (error) {}
      },
    }),
    authorize: build.query<IAuthorizeResponse, IAuthorizePayload>({
      query: (payload) => ({
        method: 'POST',
        url: replaceURLParams(API.AUTHORIZE, {
          sessionId: payload.sessionId ?? '',
        }),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const decodedJwt = decodeJwt(data.jwt);
            if (ENV.VITE_ENVIRONMENT === 'prod') {
              Hotjar.identify(decodedJwt?.merchantCustomerId ?? '', {
                customerType: decodedJwt?.customerType ?? '',
              });
            }
            dispatch(
              setSessionData({
                jwt: data.jwt,
                locale: decodedJwt?.locale ?? '',
                sessionId: decodedJwt?.sub ?? '',
                country: decodedJwt?.country ?? '',
                currency: decodedJwt?.currency ?? '',
                scale: String(decodedJwt?.scale) ?? '',
                sandbox: decodedJwt?.virtualMode ?? false,
              })
            );
          }
        } catch (error: unknown) {}
      },
    }),
  }),
});

export const isCashierApiError = (
  error: unknown
): error is ICashierApiErrorData => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof (error as Record<string, unknown>).data === 'object'
  );
};

interface ICashierApiErrorData {
  data: {
    code: string;
    message: string;
    timestamp: number;
    traceId: string;
  };
}

export const {
  useSendLogMutation,
  useMakeLogMutation,
  useSendMetricMutation,
  useLazyAuthorizeQuery,
  useGetTransactionQuery,
  useMakePaymentMutation,
  useGetPaymentMethodsQuery,
  useProceedPaymentMutation,
  useMakePciPaymentMutation,
  useLazyGetTransactionQuery,
  useLazyGetTranslationQuery,
  useMakeVirtualPaymentMutation,
  useProceedKycProcedureMutation,
  useGetPaymentMethodDetailsQuery,
  usePingProceedKyc4ProcedureQuery,
  usePingProceedKyc5ProcedureQuery,
  useMakeVirtualPciPaymentMutation,
  useDeletePaymentInstrumentMutation,
} = cashierApi;
