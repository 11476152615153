import { type ThemeOptions } from '@mui/material/styles';

const LightOrange = (): ThemeOptions['palette'] => {
  return {
    divider: 'rgb(210 100 21 / 10%)',
    info: {
      main: '#ebf7ff',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.10)',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
    error: {
      main: '#ef5350',
      dark: 'rgb(167, 58, 56)',
      light: 'rgb(242, 117, 115)',
    },
    primary: {
      main: '#d26415',
      dark: 'rgb(147, 70, 14)',
      light: 'rgb(219, 131, 67)',
    },
    secondary: {
      main: '#0499f2',
      dark: 'rgb(2, 107, 169)',
      light: 'rgb(54, 173, 244)',
    },
  };
};

export default LightOrange;
