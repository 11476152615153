import { SvgIcon, type SvgIconProps } from '@mui/material';

const Shuttle = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      className="shuttle"
      version="1.1"
      viewBox="0 0 511.993 511.993"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
    >
      <g>
        <g>
          <g>
            <path
              d="M462.025,146.637l17.85-17.85c0.158-0.158,0.308-0.321,0.45-0.483c0.425-0.4,0.85-0.812,1.312-1.275
				c29.325-29.325,31.417-77.013,30.05-102.892c-0.659-12.906-10.992-23.212-23.9-23.837c-25.821-1.338-73.496,0.725-102.904,30.129
				c-0.412,0.421-0.808,0.821-1.192,1.237c-0.167,0.142-0.329,0.292-0.488,0.45l-17.85,17.85l-24.183-24.183
				c-10.015-9.992-26.227-9.992-36.242,0L203.65,127.062c-40.128-5.734-79.237-17.143-116.154-33.883
				c-12.971-5.816-28.185-3.034-38.258,6.996l-41.7,41.7c-10.027,10.013-10.053,26.255-0.058,36.3L110.225,280.92l-12.158,12.158
				c-3.671,3.693-5.45,8.863-4.829,14.033l-30.846,15.471c-4.914,2.459-8.348,7.128-9.232,12.551
				c-0.884,5.423,0.89,10.941,4.769,14.833l52.05,52.046l52.046,52.05c3.892,3.879,9.409,5.653,14.833,4.769
				c5.423-0.884,10.092-4.318,12.551-9.232l15.471-30.846c0.636,0.097,1.278,0.158,1.921,0.184
				c4.544,0.007,8.902-1.797,12.112-5.013l12.158-12.158l102.741,102.746c4.792,4.811,11.31,7.504,18.1,7.479h0.046
				c6.816,0.009,13.352-2.705,18.158-7.537l41.7-41.7c10.02-10.062,12.809-25.256,7.017-38.221
				c-16.752-36.928-28.167-76.05-33.904-116.192l101.279-101.279c9.992-10.015,9.992-26.227,0-36.242L462.025,146.637z
				 M486.821,17.337L486.821,17.337c4.231,0.162,7.634,3.537,7.829,7.767c0.835,14.414-0.047,28.876-2.626,43.082l-48.217-48.217
				C457.99,17.395,472.429,16.511,486.821,17.337z M394.433,45.02c0.534-0.403,1.02-0.867,1.446-1.383
				c0.358-0.433,0.721-0.783,1.15-1.217c7.944-7.677,17.447-13.554,27.865-17.231L486.8,87.095
				c-3.686,10.433-9.575,19.95-17.267,27.904c-0.396,0.392-0.746,0.754-1.179,1.112c-0.502,0.413-0.954,0.885-1.346,1.404
				l-193.4,193.404l-12.106-12.106l158.231-158.227c6.658-6.675,6.658-17.479,0-24.154l-24.175-24.175
				c-6.673-6.662-17.481-6.662-24.154,0L213.177,250.489l-12.106-12.106L394.433,45.02z M377.46,158.726l-36.28,36.279
				l-24.188-24.188l36.281-36.277L377.46,158.726z M365.341,122.473l18.151-18.149l24.175,24.196l-18.139,18.14L365.341,122.473z
				 M329.114,207.071l-36.28,36.279l-24.19-24.19l36.281-36.277L329.114,207.071z M280.767,255.416l-30.238,30.237l-24.192-24.192
				l30.239-30.236L280.767,255.416z M316.996,37.849c3.346-3.339,8.763-3.339,12.108,0l24.183,24.183l-24.194,24.193l-12.097-12.097
				c-3.332-3.332-8.735-3.332-12.067,0c-3.332,3.332-3.332,8.735,0,12.067l12.097,12.097l-36.28,36.279l-12.097-12.097
				c-3.332-3.332-8.735-3.332-12.067,0c-3.332,3.332-3.332,8.735,0,12.067l12.097,12.097l-36.281,36.28l-12.095-12.098
				c-3.332-3.332-8.735-3.332-12.067,0c-3.332,3.332-3.332,8.735,0,12.067l12.095,12.097l-30.236,30.236l-24.183-24.183
				c-3.337-3.345-3.337-8.76,0-12.104L316.996,37.849z M61.304,112.241c5.054-5.028,12.684-6.42,19.188-3.5
				c34.512,15.604,70.852,26.797,108.162,33.317l-34.807,34.807c-9.992,10.013-9.992,26.224,0,36.237l16.078,16.079
				c-6.532,1.342-12.528,4.57-17.245,9.284l-0.035,0.035l-108.8-108.8L61.304,112.241z M19.546,166.103
				c-1.603-1.6-2.497-3.777-2.479-6.042c-0.003-2.297,0.91-4.5,2.537-6.121l12.173-12.173l30.236,30.236L43.73,190.289
				L19.546,166.103z M55.797,202.356l18.283-18.285l24.195,24.195L79.991,226.55L55.797,202.356z M92.057,238.617l18.285-18.285
				l30.236,30.235l-18.286,18.285L92.057,238.617z M164.746,250.533c3.235-3.239,7.626-5.058,12.204-5.054
				c4.523-0.011,8.864,1.781,12.063,4.979l30.225,30.229l-36.475,36.475l-24.183-24.183c-3.334-3.322-8.728-3.318-12.056,0.01
				c-3.328,3.328-3.333,8.722-0.01,12.056l24.183,24.183l-18.275,18.275l-42.313-42.312L164.746,250.533z M70.042,337.841
				l32.354-16.229l37.958,37.958l-24.337,24.333L70.042,337.841z M174.092,441.995l-46.012-46.017l24.342-24.342l37.954,37.954
				L174.092,441.995z M206.8,401.883l-42.313-42.313l18.275-18.275l24.183,24.184c2.154,2.162,5.299,3.008,8.247,2.22
				c2.948-0.788,5.251-3.091,6.039-6.039c0.788-2.948-0.058-6.093-2.22-8.247l-24.183-24.183l36.475-36.475l30.229,30.225
				c6.667,6.727,6.633,17.581-0.075,24.267L206.8,401.883z M243.138,389.7l18.285-18.286l30.235,30.235l-18.285,18.285
				L243.138,389.7z M285.44,432.001l18.285-18.285l24.195,24.195l-18.286,18.284L285.44,432.001z M358.05,492.387
				c-1.621,1.627-3.824,2.541-6.121,2.537h-0.017c-2.26,0.012-4.431-0.881-6.029-2.479L321.7,468.262l18.286-18.284l30.236,30.236
				L358.05,492.387z M403.271,431.537L403.271,431.537c2.902,6.497,1.502,14.11-3.521,19.15l-17.46,17.46l-108.8-108.8l0.035-0.035
				c4.714-4.717,7.942-10.713,9.284-17.245l16.079,16.079c10.012,9.994,26.226,9.994,36.237,0l34.807-34.807
				C376.456,360.661,387.657,397.014,403.271,431.537z M474.142,194.995L323.058,346.078c-3.348,3.329-8.756,3.329-12.104,0
				l-24.183-24.183l30.236-30.236l12.097,12.095c3.332,3.332,8.735,3.332,12.067,0c3.332-3.332,3.332-8.735,0-12.067l-12.098-12.095
				l36.28-36.282l12.097,12.097c3.332,3.332,8.734,3.332,12.067,0c3.332-3.332,3.332-8.734,0-12.067l-12.097-12.096l36.279-36.28
				l12.098,12.097c3.334,3.32,8.727,3.315,12.054-0.013c3.327-3.327,3.333-8.72,0.012-12.054l-12.097-12.097l24.193-24.194
				l24.184,24.184C477.479,186.233,477.479,191.649,474.142,194.995z"
            />
            <path
              d="M413.375,74.424c2.5-2.475,10.808-1.396,18.2,5.992c7.383,7.383,8.479,15.712,5.992,18.2
				c-3.332,3.332-3.332,8.735,0,12.067s8.735,3.332,12.067,0c10.192-10.188,7.558-28.783-5.992-42.333
				c-13.55-13.546-32.142-16.175-42.333-5.992c-3.332,3.332-3.332,8.735,0,12.067C404.64,77.756,410.043,77.756,413.375,74.424z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </SvgIcon>
);

export default Shuttle;
