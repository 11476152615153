import { CssBaseline, StyledEngineProvider } from '@mui/material';
import {
  createTheme,
  type CustomThemeOptions,
  type Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { type ReactNode, useMemo } from 'react';

import { useAppSelector } from '@hooks';
import componentsOverride from '@themes/overrides';
import Palette from '@themes/palette';
import Typography from '@themes/typography';

interface ThemeCustomizationProps {
  children?: ReactNode;
}

const ThemeCustomization: React.FC<ThemeCustomizationProps> = ({
  children,
}) => {
  const { presetColor = 'default' } = useAppSelector(
    (state) => state.currentSettings
  );

  const theme: Theme = useMemo<Theme>(
    () => Palette('light', presetColor),
    [presetColor]
  );

  const themeTypography = Typography(`'Poppins', sans-serif`);

  const themeOptions = useMemo(
    () => ({
      direction: 'ltr',
      palette: theme.palette,
      typography: themeTypography,
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 1080,
          lg: 1266,
          xl: 1536,
        },
      },
    }),
    [theme, themeTypography]
  );

  const themes = createTheme(themeOptions as CustomThemeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeCustomization;
