import { useMediaQuery } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { usePaymentMethodData } from '@hooks/usePaymentMethodData';
import { isEmpty } from '@utils';

import { useAppDispatch, useAppSelector } from '@hooks';
import { type IProvider } from '@modules/types';
import { type Intent } from '@pages/payment/types';
import {
  setPage,
  setPaymentMethod,
  setProhibitedAmount,
  setSessionData,
} from '@store/reducers/currentSettings';
import { decodeJwt } from '@utils/jwtDecode';

interface IPayment {
  renderPaymentMethod: (props: IProvider) => React.JSX.Element;
}

const Payment: React.FC<IPayment> = ({ renderPaymentMethod }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isMatchesSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const isMatchesMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const {
    jwt,
    intent,
    hideAmount,
    currency = '',
    predefinedAmounts,
    prohibitedAmount = 0,
    amount: initialAmount = 10,
    additionalAmountValidationRequired,
    paymentMethod: currentPaymentMethod,
  } = useAppSelector((state) => state.currentSettings);

  const { minAmount, maxAmount, isDepositFlow, manualInputProhibited } =
    usePaymentMethodData({
      currentPaymentMethod,
      intent: (intent as Intent) ?? '',
    });

  const [amount, setAmount] = useState<null | number | string>(
    initialAmount ?? 10
  );

  useEffectOnce(() => {
    dispatch(setPage('payment'));
  });

  useEffect(() => {
    if (hideAmount) {
      setAmount(10);
    }
  }, [hideAmount]);

  useEffect(() => {
    if (!intent) {
      const decodedJwt = decodeJwt(jwt ?? '');
      dispatch(
        setSessionData({
          intent,
          amount: '',
          jwt: jwt ?? '',
          sessionId: decodedJwt?.sub,
          locale: decodedJwt?.locale,
          country: decodedJwt?.country,
          currency: decodedJwt?.currency,
          scale: String(decodedJwt?.scale),
          sandbox: decodedJwt?.virtualMode,
        })
      );
      dispatch(setPaymentMethod(currentPaymentMethod));
    }
  }, [currentPaymentMethod, dispatch, intent, jwt]);

  useEffect(() => {
    if (manualInputProhibited && !isEmpty(predefinedAmounts) && !hideAmount) {
      const numericValues = predefinedAmounts?.map(Number) ?? [];
      const minValue = Math.min(...numericValues);

      if (!numericValues.includes(Number(initialAmount))) {
        const nearestMinValue = numericValues.reduce((prev, curr) => {
          if (curr < Number(initialAmount) && curr > prev) {
            return curr;
          }
          return prev;
        }, minValue);
        setAmount(nearestMinValue);
        dispatch(setProhibitedAmount(nearestMinValue));
      } else {
        dispatch(setProhibitedAmount(Number(initialAmount)));
      }
    }
  }, [
    dispatch,
    hideAmount,
    initialAmount,
    manualInputProhibited,
    predefinedAmounts,
  ]);

  const handleChangeAmount = useCallback(
    (value: number | string): void => {
      setAmount(value);

      if (manualInputProhibited) {
        dispatch(setProhibitedAmount(Number(value)));
      }
    },
    [dispatch, manualInputProhibited]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const indexOfPoint = e.target.value.indexOf('.');
      const numberOfPeriods = e.target.value
        ? e.target.value.split('').filter((i: string) => i === '.').length
        : 0;
      const isMinusExist = e.target.value.indexOf('-');
      const [wholePart, decimalPart] = e.target.value.split('.');
      if (indexOfPoint > 0) {
        if (
          (decimalPart?.toString().length <= 2 &&
            +wholePart + 1 <= maxAmount &&
            numberOfPeriods <= 1 &&
            isMinusExist < 0) ||
          !e.target.value
        ) {
          handleChangeAmount(e.target.value.trim());
        }
      } else {
        if (
          (Number(e.target.value) &&
            maxAmount &&
            maxAmount >= Number(e.target.value)) ||
          !e.target.value
        ) {
          handleChangeAmount(e.target.value.trim());
        }
      }
    },
    [handleChangeAmount, maxAmount]
  );

  const isMultipleHundred = useMemo((): boolean => {
    const isMultiple = Number(amount) % 100 === 0;

    return amount !== '' && additionalAmountValidationRequired && !isMultiple;
  }, [additionalAmountValidationRequired, amount]);

  const isAmountOutOfRange = useMemo(
    (): boolean =>
      !(
        minAmount &&
        minAmount <= Number(amount) &&
        maxAmount &&
        maxAmount >= Number(amount)
      ),
    [minAmount, maxAmount, amount]
  );

  const paymentText = useMemo(
    () =>
      isDepositFlow
        ? t('paymentSubmission.deposit')
        : t('paymentSubmission.withdraw'),
    [isDepositFlow, t]
  );

  const amountErrorText = useMemo(
    () =>
      additionalAmountValidationRequired &&
      isMultipleHundred &&
      Number(amount) >= minAmount
        ? t('error.amountNotMultipleOfHundred')
        : t('paymentAmount.error', {
            min: minAmount,
            max: maxAmount,
          }),
    [
      additionalAmountValidationRequired,
      amount,
      isMultipleHundred,
      maxAmount,
      minAmount,
      t,
    ]
  );

  return renderPaymentMethod({
    amount,
    currency,
    minAmount,
    maxAmount,
    paymentText,
    isMatchesSM,
    isMatchesMD,
    handleChange,
    isDepositFlow,
    amountErrorText,
    isMultipleHundred,
    handleChangeAmount,
    isAmountOutOfRange,
    manualInputProhibited,
    additionalAmountValidationRequired: false,
    prohibitedAmount: Number(prohibitedAmount),
    paymentAmountInfoText: t('paymentAmount.info'),
  });
};

export default Payment;
