import i18n from 'i18next';
import { useEffect } from 'react';

import { useLazyGetTranslationQuery } from '@api';
import { ROUTES } from '@constants/routes';
import { delay } from '@utils';

import enDictionary from '@i18n/en.json';

const useI18n = () => {
  const [getTranslation, { isLoading }] = useLazyGetTranslationQuery();

  useEffect(() => {
    const isStatusPage = window.location.pathname === ROUTES.STATUS_TRANSACTION;

    const fetchTranslationData = async () => {
      try {
        const { data } = await getTranslation(isStatusPage);

        const language = data?.locale
          ? data.locale.slice(0, 2).toLowerCase()
          : i18n.language;

        void i18n.init({
          fallbackLng: 'en',
          lng: language ?? 'en',
          interpolation: {
            escapeValue: false,
          },
          resources: {
            en: {
              translation: enDictionary,
            },
            ...(data?.locale && {
              [language]: {
                translation: data.translations,
              },
            }),
          },
        });
      } catch (error) {
        console.error('Failed to fetch translation data:', error);
      }
    };

    delay(() => fetchTranslationData(), isStatusPage ? 2000 : 500);
  }, [getTranslation]);

  return { i18n, isLoading };
};

export default useI18n;
