import React, { Suspense } from 'react';

import Loader from '@components/common/Loader/Loader';
import { type IProvider } from '@modules/types';

type ComponentWithProps<P> = React.FC<P>;

export const Suspendable =
  <P extends IProvider | Record<string, unknown>>(
    Component: ComponentWithProps<P>
  ) =>
  (props: P) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
