import { ENV } from '@constants/env';

const mode = ENV.VITE_ENVIRONMENT === 'prod' ? 'production' : 'sandbox';

interface IVoltPaymentData {
  country: null | string;
  id: null | string;
  language: null | string;
  token: null | string;
  type: string;
}

class VoltIntegration {
  volt;

  constructor() {
    this.volt = window.Volt && new window.Volt({ mode });
  }

  createPayment(data: IVoltPaymentData) {
    const paymentContainer = this.volt.payment({
      payment: {
        id: data.id,
        token: data.token,
      },
      ...(data.country && { country: data.country }),
      language: data.language ?? window.navigator.language.slice(0, 2),
    });
    const paymentComponent = paymentContainer.createPayment();
    paymentComponent.mount('#volt-payment-component');
  }
}

const volt = new VoltIntegration();

export { volt, type IVoltPaymentData };
