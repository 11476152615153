import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function formattedDate(date: null | number, timeZone?: string): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    timeStyle: 'medium',
    ...(timeZone ? { timeZone } : { timeZone: 'GMT' }),
  }).format(date);
}

export const isValidDateOfBirth = (date: number | string): boolean =>
  !dayjs(date).isBefore('1900-01-01') &&
  !dayjs(date).isAfter(dayjs()) &&
  (dayjs(date).format('YYYY-MM-D') === date ||
    dayjs(date).format('YYYY-MM-DD') === date);

export const isUserAdult = (date: number | string): boolean =>
  dayjs().diff(dayjs(date), 'year') >= 18 &&
  dayjs().diff(dayjs(date), 'year') <= 100;
