import { type ThemeOptions } from '@mui/material/styles';

const DarkGreen = (): ThemeOptions['palette'] => {
  return {
    divider: 'rgb(36 239 157 / 10%)',
    info: {
      main: '#0e2149',
    },
    action: {
      disabled: '#2e2c31',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#2e2c31',
      default: '#1a1a1a',
    },
    error: {
      main: '#ef5350',
      dark: 'rgb(167, 58, 56)',
      light: 'rgb(242, 117, 115)',
    },
    primary: {
      main: '#24ef9d',
      dark: 'rgb(25, 167, 109)',
      light: 'rgb(79, 242, 176)',
    },
    secondary: {
      main: '#0499f2',
      dark: 'rgb(2, 107, 169)',
      light: 'rgb(54, 173, 244)',
    },
    text: {
      secondary: '#000',
      primary: '#cacbcc',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
  };
};

export default DarkGreen;
