import { IconButton, Stack, useMediaQuery } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ArrowBackSvg from '@assets/svg/ArrowBack';

import { ROUTES } from '@constants';
import { useAppSelector } from '@hooks';

const Header = () => {
  const location = useLocation();
  const { page } = useAppSelector((state) => state.currentSettings);

  const isMatchesSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const url = useMemo(() => {
    if (location.pathname.includes(ROUTES.EMBEDDED.ROOT)) {
      return `${location.pathname.replace(ROUTES.EMBEDDED.ROOT, '')}`;
    }

    if (location.pathname === ROUTES.INTEGRATIONS.PAYMENT_IQ_HOSTED_FIELDS) {
      return ROUTES.DEPOSIT_PAYMENT_IQ;
    }

    return null;
  }, [location.pathname]);

  const showBackButton = useMemo(
    () => ['embedded', 'payment', 'proceed'].includes(page),
    [page]
  );

  if (!showBackButton) {
    return null;
  }

  return (
    <Stack
      sx={{
        height: '2.5rem',
        padding: '0 1rem 0',
      }}
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <Stack alignItems="center" direction="row">
        <IconButton
          state={{
            from: location?.state?.from,
          }}
          sx={{
            marginRight: '1rem',
            ...(isMatchesSM && { marginLeft: '-1rem' }),
          }}
          component={Link}
          to={url ?? `/${location?.state?.from}`}
        >
          <ArrowBackSvg color="primary" fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Header;
