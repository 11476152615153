import { type ThemeOptions } from '@mui/material/styles';

const Default = (): ThemeOptions['palette'] => {
  return {
    divider: '#F1F4F7',
    error: {
      main: '#ef5350',
    },
    success: {
      main: '#52c41a',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.10)',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
    info: {
      main: '#ebf7ff',
      dark: 'rgb(164, 172, 178)',
      light: 'rgb(239, 248, 255)',
    },
    primary: {
      main: '#4fa7ff',
      dark: 'rgb(55, 116, 178)',
      light: 'rgb(114, 184, 255)',
    },
    secondary: {
      main: '#675CE2',
      dark: 'rgb(72, 64, 158)',
      light: 'rgb(133, 124, 231)',
    },
  };
};

export default Default;
