import { Stack, Typography } from '@mui/material';
import { Component, type ErrorInfo, type ReactNode } from 'react';
import { withTranslation } from 'react-i18next';

import Astronaut from '@assets/svg/Astronaut';
import Shuttle from '@assets/svg/Shuttle';
import World from '@assets/svg/World';
import * as Sentry from '@sentry/browser';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class CashierErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('info', info);
    console.error('error', error);
    this.setState({ hasError: true });
    Sentry.captureException(error);
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <Stack
          sx={{
            textAlign: 'center',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Stack justifyContent="center">
            <World color="primary" data-testid="worldIcon" />
            <Shuttle color="primary" data-testid="worldIcon" />
            <Astronaut color="primary" data-testid="worldIcon" />
          </Stack>
          <Typography
            sx={{
              marginTop: '3rem',
              fontSize: '1.75rem',
            }}
            variant="h1"
          >
            {t('error.common')}
          </Typography>
          <Typography
            sx={{
              marginTop: '1rem',
            }}
            variant="body1"
          >
            {t('common.somethingGoWrong')}
          </Typography>
        </Stack>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(CashierErrorBoundary);
