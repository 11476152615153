import { type Theme } from '@mui/material/styles';

export default function InputBase(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: 0,
          left: -5,
          fontWeight: 400,
          fontSize: '1rem',
          color: theme.palette.text.primary,
          '&.Mui-focused': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          border: 'none',
          fontWeight: 700,
          marginTop: '1rem',
          height: '3.75rem',
          padding: '0.375rem',
          fontSize: '1.563rem',
          color: theme.palette.text.primary,
          borderRadius: '1.25rem !important',
          backgroundColor: theme.palette.background.paper,
          input: {
            '&::placeholder': {
              opacity: 0.7,
              fontWeight: 400,
              fontSize: '1rem',
              transform: 'translateY(-15%)',
              color: theme.palette.text.primary,
            },
          },
        },
        root: {
          border: 'none',
          fontWeight: 900,
          marginTop: '1rem',
          height: '4.375rem',
          padding: '0.375rem',
          fontSize: '1.875rem',
          color: theme.palette.primary.main,
          borderRadius: '1.25rem !important',
          legend: {
            width: '0',
          },
          backgroundColor: theme.palette.background.paper,
          fieldset: {
            border: '1px solid',
            borderColor: 'rgba(18, 15, 54, 0.1)',
          },
          input: {
            '&::placeholder': {
              opacity: 0.7,
              fontWeight: 900,
              fontSize: '1.875rem',
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
