import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import Astronaut from '@assets/svg/Astronaut';
import Shuttle from '@assets/svg/Shuttle';
import World from '@assets/svg/World';
import * as Sentry from '@sentry/browser';

export const ErrorComponent = () => {
  const { t } = useTranslation();
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <Stack
      sx={{
        textAlign: 'center',
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack justifyContent="center">
        <World color="primary" data-testid="worldIcon" />
        <Shuttle color="primary" data-testid="worldIcon" />
        <Astronaut color="primary" data-testid="worldIcon" />
      </Stack>
      <Typography
        sx={{
          marginTop: '3rem',
          fontSize: '1.75rem',
        }}
        variant="h1"
      >
        {t('error.common')}
      </Typography>
      <Typography
        sx={{
          marginTop: '1rem',
        }}
        variant="body1"
      >
        {t('common.somethingGoWrong')}
      </Typography>
    </Stack>
  );
};
