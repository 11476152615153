import { type FC, type ReactNode, useCallback, useEffect } from 'react';

import { useMakeLogMutation } from '@api';

import { useAppSelector } from '@hooks';

interface ILogger {
  children: ReactNode;
}
export const GlobalErrorLogger: FC<ILogger> = ({ children }) => {
  const { jwt } = useAppSelector((state) => state.currentSettings);
  const [makeLog] = useMakeLogMutation();

  const handleMakeLog = useCallback(
    async (message: string) => {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${jwt}`);
      headers.append('Content-Type', 'application/json');

      await makeLog({
        message,
        headers,
        level: 'ERROR',
      });
    },
    [jwt, makeLog]
  );

  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      void handleMakeLog(JSON.stringify(event.reason));
    };

    const handleError = (event: ErrorEvent) => {
      void handleMakeLog(
        `Error occurred: ${event.message}, at ${event.filename}:, ${event.lineno}, ${event.colno}`
      );
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener(
        'unhandledrejection',
        handleUnhandledRejection
      );
      window.removeEventListener('error', handleError);
    };
  }, [handleMakeLog]);

  return <>{children}</>;
};
