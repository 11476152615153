import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import AuthProvider from '@auth/AuthProvider';
import CashierErrorBoundary from '@components/common/CashierErrorBoundary';
import { ErrorComponent } from '@components/common/ErrorComponent';
import { Suspendable } from '@components/common/Suspendable';
import { ROUTES } from '@constants';
import I18nProvider from '@i18n/I18nProvider';
import EmbeddedLayout from '@layout/EmbeddedLayout';
import MainLayout from '@layout/MainLayout';
import { type IProvider } from '@modules/types';
import Payment from '@pages/payment';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const {
  ALL,
  INFO,
  ROOT,
  DEPOSIT,
  WITHDRAWAL,
  DEPOSIT_PCI,
  MAINTENANCE,
  CONFIRMATION,
  DEPOSIT_VOLT,
  WITHDRAWAL_PCI,
  DEPOSIT_EUPAGO,
  DEPOSIT_BOODIL,
  DEPOSIT_PAYCASH,
  VIRTUAL_PAYMENT,
  DEPOSIT_WEBPAYZ,
  WITHDRAWAL_VOLT,
  DEPOSIT_CHECKOUT,
  DEPOSIT_MIFINITY,
  WITHDRAWAL_EUPAGO,
  DEPOSIT_PCI_NUVEI,
  WITHDRAWAL_PAYCASH,
  DEPOSIT_PRAXIS_HPF,
  DEPOSIT_SAFECHARGE,
  STATUS_TRANSACTION,
  WITHDRAWAL_WEBPAYZ,
  DEPOSIT_PAYMENT_IQ,
  DEPOSIT_GATECHARGE,
  WITHDRAWAL_CITIZEN,
  WITHDRAWAL_MIFINITY,
  DEPOSIT_MICROPAYMENT,
  DEPOSIT_PAYWITHCLICK,
  DEPOSIT_PCI_DOLCEPAY,
  DEPOSIT_PCI_WORLDCARD,
  WITHDRAWAL_SAFECHARGE,
  WITHDRAWAL_PRAXIS_HPF,
  WITHDRAWAL_PAYMENT_IQ,
  WITHDRAWAL_MICROPAYMENT,
  DEPOSIT_PRAXIS_HPF_INTERNAL,
  DEPOSIT_SAFECHARGE_APPLEPAY,
  WITHDRAWAL_PRAXIS_HPF_INTERNAL,
  INTEGRATIONS: { PAYMENT_IQ_HOSTED_FIELDS },
  EMBEDDED: { DEPOSIT_VOLT_EMBEDDED, DEPOSIT_MIFINITY_EMBEDDED },
} = ROUTES;

const Confirmation = Suspendable(lazy(() => import('@pages/confirmation')));
const EmbeddedWidget = Suspendable(lazy(() => import('@pages/embedded')));
const InfoPage = Suspendable(lazy(() => import('@pages/info')));
const MaintenancePage = Suspendable(lazy(() => import('@pages/maintenance')));
const Methods = Suspendable(lazy(() => import('@pages/methods')));
const Status = Suspendable(lazy(() => import('@pages/status')));
const PaymentIqHostedFields = Suspendable(
  lazy(() => import('@pages/integrations/paymentIq'))
);
const VirtualPayment = Suspendable(lazy(() => import('@pages/virtualPayment')));

// WARNING: Place constants in the alphabetical order (CommonProvider must be first)

const CommonProvider = Suspendable<IProvider>(
  lazy(() => import('@modules/common'))
);

const Citizen = Suspendable<IProvider>(lazy(() => import('@modules/citizen')));

const Checkout = Suspendable<IProvider>(
  lazy(() => import('@modules/checkout'))
);

const Eupago = Suspendable<IProvider>(lazy(() => import('@modules/eupago')));

const GateCharge = Suspendable<IProvider>(
  lazy(() => import('@modules/gatecharge'))
);

// Turn off KYC flow
// const GigadatInterac = Suspendable<IProvider>(
//   lazy(() => import('@modules/gigadatInterac'))
// );

const Micropayment = Suspendable<IProvider>(
  lazy(() => import('@modules/micropayment'))
);

const MifinityEmbedded = Suspendable<IProvider>(
  lazy(() => import('@modules/mifinityEmbedded'))
);

const Paycash = Suspendable<IProvider>(lazy(() => import('@modules/paycash')));

const PaymentIq = Suspendable<IProvider>(
  lazy(() => import('@modules/paymentIq'))
);

const PayWithClick = Suspendable<IProvider>(
  lazy(() => import('@modules/payWithClick'))
);

const PCI = Suspendable<IProvider>(lazy(() => import('@modules/pci')));

const PciWorldcard = Suspendable(
  lazy(() => import('@modules/pci/integrations/worldcard'))
);

const PciNuvei = Suspendable(
  lazy(() => import('@modules/pci/integrations/nuvei'))
);

const PciDolcepay = Suspendable(
  lazy(() => import('@modules/pci/integrations/dolcepay'))
);

const PraxisHpf = Suspendable<IProvider>(
  lazy(() => import('@modules/praxisHpf'))
);

const PraxisHpfInternal = Suspendable<IProvider>(
  lazy(() => import('@modules/praxisHpfInternal'))
);

const SafeCharge = Suspendable<IProvider>(
  lazy(() => import('@modules/safecharge'))
);

const SafechargeApplePay = Suspendable<IProvider>(
  lazy(() => import('@modules/safecharge/ApplePay'))
);

const VoltEmbedded = Suspendable<IProvider>(
  lazy(() => import('@modules/voltEmbedded'))
);

const Webpayz = Suspendable<IProvider>(lazy(() => import('@modules/webpayz')));

const Boodil = Suspendable<IProvider>(lazy(() => import('@modules/boodil')));

export const routerConfig = sentryCreateBrowserRouter([
  {
    path: ROOT,
    errorElement: (
      <Sentry.ErrorBoundary
        fallback={
          <I18nProvider>
            <ErrorComponent />
          </I18nProvider>
        }
      />
    ),
    element: (
      <AuthProvider>
        <I18nProvider>
          <CashierErrorBoundary>
            <MainLayout />
          </CashierErrorBoundary>
        </I18nProvider>
      </AuthProvider>
    ),
    children: [
      {
        index: true,
        element: <Methods />,
      },
      {
        path: ALL,
        element: <Methods />,
      },
      {
        path: DEPOSIT_SAFECHARGE_APPLEPAY,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <SafechargeApplePay {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <CommonProvider {...props} />
            )}
          />
        ),
      },
      // Turn off KYC FLOW
      // {
      //   path: DEPOSIT_GIGADAT_INTERAC,
      //   element: (
      //     <Payment
      //       renderPaymentMethod={(props: IProvider) => (
      //         <GigadatInterac {...props} />
      //       )}
      //     />
      //   ),
      // },
      // {
      //   path: WITHDRAWAL_GIGADAT_INTERAC,
      //   element: (
      //     <Payment
      //       renderPaymentMethod={(props: IProvider) => (
      //         <GigadatInterac {...props} />
      //       )}
      //     />
      //   ),
      // },
      {
        path: WITHDRAWAL,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <CommonProvider {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_SAFECHARGE,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <SafeCharge {...props} />
            )}
          />
        ),
      },
      {
        path: WITHDRAWAL_SAFECHARGE,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <SafeCharge {...props} />
            )}
          />
        ),
      },
      {
        path: WITHDRAWAL_PAYCASH,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Paycash {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_PAYCASH,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Paycash {...props} />}
          />
        ),
      },
      {
        path: WITHDRAWAL_MICROPAYMENT,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <Micropayment {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_MICROPAYMENT,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <Micropayment {...props} />
            )}
          />
        ),
      },
      {
        path: WITHDRAWAL_EUPAGO,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Eupago {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_EUPAGO,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Eupago {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_PRAXIS_HPF,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PraxisHpf {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_PRAXIS_HPF_INTERNAL,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <PraxisHpfInternal {...props} />
            )}
          />
        ),
      },
      {
        path: WITHDRAWAL_PRAXIS_HPF_INTERNAL,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <PraxisHpfInternal {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_PAYMENT_IQ,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PaymentIq {...props} />}
          />
        ),
      },
      {
        path: WITHDRAWAL_PRAXIS_HPF,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PraxisHpf {...props} />}
          />
        ),
      },
      {
        path: WITHDRAWAL_PAYMENT_IQ,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PaymentIq {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_VOLT,
        element: <EmbeddedLayout />,
        children: [
          {
            element: <EmbeddedWidget />,
            path: DEPOSIT_VOLT_EMBEDDED,
          },
          {
            index: true,
            element: (
              <Payment
                renderPaymentMethod={(props: IProvider) => (
                  <VoltEmbedded {...props} />
                )}
              />
            ),
          },
        ],
      },
      {
        path: DEPOSIT_CHECKOUT,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Checkout {...props} />}
          />
        ),
      },
      {
        path: WITHDRAWAL_VOLT,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <VoltEmbedded {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_WEBPAYZ,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Webpayz {...props} />}
          />
        ),
      },
      {
        path: WITHDRAWAL_WEBPAYZ,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Webpayz {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_BOODIL,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Boodil {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_MIFINITY,
        element: <EmbeddedLayout />,
        children: [
          {
            element: <EmbeddedWidget />,
            path: DEPOSIT_MIFINITY_EMBEDDED,
          },
          {
            index: true,
            element: (
              <Payment
                renderPaymentMethod={(props: IProvider) => (
                  <MifinityEmbedded {...props} />
                )}
              />
            ),
          },
        ],
      },
      {
        path: WITHDRAWAL_MIFINITY,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <MifinityEmbedded {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_PAYWITHCLICK,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <PayWithClick {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_GATECHARGE,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <GateCharge {...props} />
            )}
          />
        ),
      },
      {
        path: WITHDRAWAL_CITIZEN,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <Citizen {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_PCI,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PCI {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_PCI_WORLDCARD,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <PciWorldcard {...props} />
            )}
          />
        ),
      },
      {
        path: DEPOSIT_PCI_NUVEI,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PciNuvei {...props} />}
          />
        ),
      },
      {
        path: DEPOSIT_PCI_DOLCEPAY,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => (
              <PciDolcepay {...props} />
            )}
          />
        ),
      },
      {
        path: WITHDRAWAL_PCI,
        element: (
          <Payment
            renderPaymentMethod={(props: IProvider) => <PCI {...props} />}
          />
        ),
      },
      {
        element: <Status />,
        path: STATUS_TRANSACTION,
      },
      {
        path: CONFIRMATION,
        element: <Confirmation />,
      },
      {
        path: PAYMENT_IQ_HOSTED_FIELDS,
        element: <PaymentIqHostedFields />,
      },
      {
        path: VIRTUAL_PAYMENT,
        element: <VirtualPayment />,
      },
    ],
  },
  {
    path: INFO,
    element: <MainLayout />,
    children: [
      {
        path: INFO,
        element: <InfoPage />,
      },
    ],
  },
  {
    path: MAINTENANCE,
    element: <MainLayout />,
    children: [
      {
        path: MAINTENANCE,
        element: <MaintenancePage />,
      },
    ],
  },
]);
