import { type PresetColor } from '@themes/overrides/config';
import DarkGreenTheme from '@themes/theme/DarkGreen';
import DarkOrangeTheme from '@themes/theme/DarkOrange';
import DarkPurpleTheme from '@themes/theme/DarkPurple';
import DefaultTheme from '@themes/theme/Default';
import LightBlueTheme from '@themes/theme/LightBlue';
import LightGreenTheme from '@themes/theme/LightGreen';
import LightOrangeTheme from '@themes/theme/LightOrange';
import LightPurpleTheme from '@themes/theme/LightPurple';

const Theme = (presetColor: PresetColor) => {
  switch (presetColor) {
    case 'light_blue':
      return LightBlueTheme();
    case 'dark_green':
      return DarkGreenTheme();
    case 'dark_purple':
      return DarkPurpleTheme();
    case 'dark_orange':
      return DarkOrangeTheme();
    case 'light_orange':
      return LightOrangeTheme();
    case 'light_green':
      return LightGreenTheme();
    case 'light_purple':
      return LightPurpleTheme();
    default:
      return DefaultTheme();
  }
};

export default Theme;
