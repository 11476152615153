import { createAction, createReducer } from '@reduxjs/toolkit';

interface IPraxisHpfSettings {
  cardExpiration: null | string;
  cardIssuer: null | string;
  cardMaskedPan: null | string;
  cardNetwork: null | string;
  isCardValid: boolean;
  isSessionReady: boolean;
  praxisHpfToken: null | string;
}

const initialState: IPraxisHpfSettings = {
  cardIssuer: null,
  cardNetwork: null,
  isCardValid: false,
  cardMaskedPan: null,
  cardExpiration: null,
  praxisHpfToken: null,
  isSessionReady: false,
};

export const setPraxisHpfCardInfoData = createAction<{
  cardExp: string;
  cardIssuerName: string;
  cardNumber: string;
  cardType: string;
}>('providerSettings/setPraxisHpfCardInfoData');

export const setIsCardValid = createAction<boolean>(
  'praxisHpfSettings/setIsCardValid'
);

export const setPraxisHpfToken = createAction<null | string>(
  'praxisHpfSettings/setPraxisHpfToken'
);

export const setInitSessionReady = createAction<boolean>(
  'praxisHpfSettings/setInitSessionReady'
);

export const praxisHpfSettings = createReducer(initialState, (builder) => {
  builder.addCase(setPraxisHpfCardInfoData, (state, action) => {
    state.cardMaskedPan = action.payload.cardNumber;
    state.cardNetwork = action.payload.cardType;
    state.cardExpiration = action.payload.cardExp;
    state.cardIssuer = action.payload.cardIssuerName;
  });
  builder.addCase(setIsCardValid, (state, action) => {
    state.isCardValid = action.payload;
  });
  builder.addCase(setInitSessionReady, (state, action) => {
    state.isSessionReady = action.payload;
  });
  builder.addCase(setPraxisHpfToken, (state, action) => {
    state.praxisHpfToken = action.payload;
  });
});
