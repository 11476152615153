import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import { useAuth } from '@auth/useAuth';
import Loader from '@components/common/Loader/Loader';

function AuthProvider({ children }: { children: JSX.Element }) {
  const { token, isAuthQueryError } = useAuth();
  const isStatusPage = window.location.pathname === ROUTES.STATUS_TRANSACTION;

  if (isAuthQueryError && !token) {
    return (
      <Navigate
        replace
        state={{ message: 'Session Expired' }}
        to={ROUTES.INFO}
      />
    );
  }

  return !token && !isStatusPage ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>{children}</Suspense>
  );
}

export default AuthProvider;
