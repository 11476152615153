import SvgIcon from '@mui/material/SvgIcon';
import { type SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

const ArrowBackSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      height="24"
      viewBox="-5 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 22L0 12L10 2l1.775 1.775L3.55 12l8.225 8.225z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default ArrowBackSvg;
